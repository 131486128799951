<template>
  <div v-if="holidaySummary && renderCharts">
      <div class="row p-2">
              <div class="col-sm-4">
                  <div class="card card-bordered">
                    <div class="card-inner">
                           <div class="card-title-group align-start mb-2">
                               <div class="card-title">
                                   <h6 class="title" v-if="holidaySummary.entitlementIncludesPublicHolidays">Holidays Taken</h6>
                                   <h6 class="title" v-else >Holidays Taken</h6>
                               </div>
                               <div class="card-tools">
                                   <!-- <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left" title="" data-original-title="Total active subscription"></em> -->
                               </div>
                           </div>
                           <div class="align-end g-1 flex-md-nowrap">
                               <div class="nk-sale-data">
                                   <span class="amount">{{holidaySummary.holidaysTakenDuration}} {{holidaySummary.durationMeasurement}}</span>
                                   <span class="sub-title"><span class=" text-info mr-2">{{holidaySummary.remainingHolidayDuration}}</span>remaining</span>
                               </div>
                               <div class="nk-sales-ck" >
                                   <div style="margin-top: -50px; height:30px;">
                                        <apexchart :height="170" type="donut" :options="chartTakeOptions" :series="takenSeries"></apexchart>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
              </div>
              <div class="col-sm-4">
                  <div class="card card-bordered">
                    <div class="card-inner">
                           <div class="card-title-group align-start mb-2">
                               <div class="card-title">
                                   <h6 class="title">Holidays Scheduled</h6>
                               </div>
                               <div class="card-tools">
                                   <!-- <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left" title="" data-original-title="Total active subscription"></em> -->
                               </div>
                           </div>
                           <div class="align-end g-1 flex-md-nowrap">
                               <div class="nk-sale-data">
                                   <span class="amount">{{holidaySummary.holidaysScheduledDuration}} {{holidaySummary.durationMeasurement}}</span>
                                   <span class="sub-title"><span class="text-info mr-1">{{holidaySummary.holidaysUnapproved}}</span> un-approved</span>
                               </div>
                               <div class="nk-sales-ck"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                                    <div style="margin-top: -50px; height:30px;">
                                        <apexchart :height="170" type="donut" :options="chartScheduledOptions" :series="scheduledSeries"></apexchart>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
              </div>
              <div class="col-sm-4">
                  <div class="card card-bordered">
                    <div class="card-inner">
                           <div class="card-title-group align-start mb-2">
                               <div class="card-title">
                                   <h6 class="title">Sickness</h6>
                               </div>
                               <div class="card-tools">
                                   <!-- <em class="card-hint icon ni ni-help-fill" data-toggle="tooltip" data-placement="left" title="" data-original-title="Total active subscription"></em> -->
                               </div>
                           </div>
                           <div class="align-end g-1 flex-md-nowrap">
                               <div class="nk-sale-data">
                                   <span class="amount">{{holidaySummary.sickDuration}} {{holidaySummary.durationMeasurement}}</span>
                                   <span class="sub-title"><span class="text-info mr-2">{{((holidaySummary.sickDuration / holidaySummary.daysWorkedThisYear) * 100).toFixed(2)}}%</span>of {{holidaySummary.daysWorkedThisYear}}</span>
                               </div>
                               <div class="nk-sales-ck">
                                    <div style="margin-top: -50px; height:30px;">
                                        <apexchart :height="170" type="donut" :options="chartSicknessOptions" :series="sicknessSeries"></apexchart>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
              </div>
            </div>
  </div>
</template>

<script>
export default {
    props: {
        userId: {
            type: String
        },
    },
    created (){
        this.get_User_HolidaySummary()
    },
     methods: {
        get_User_HolidaySummary () {
            let searchFilter = { userId: this.userId, startYear: '2022'}
            this.$http.post('/people/Get_User_HolidaySummary/', searchFilter)
                .then((response) => {
                    this.holidaySummary = response.data
                    this.renderCharts = true
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
        },
    },
    computed: {
                takenSeries: function () {
                    if (this.holidaySummary) {                    
                       let ret = [this.holidaySummary.holidaysTakenDuration,this.holidaySummary.entitlementDuration]
                        return ret
                    } else 
                    {
                        return 0
                    }
                },
                scheduledSeries: function () {
                    if (this.holidaySummary) {                    
                        let ret = [this.holidaySummary.entitlementDuration,this.holidaySummary.holidaysUnapprovedDuration]
                        return ret
                    } else 
                    {
                        return 0
                    }
                },
                sicknessSeries: function () {
                    if (this.holidaySummary) {
                        let ret = [this.holidaySummary.sickDuration,this.holidaySummary.daysWorkedThisYear]
                        return ret
                    } else 
                    {
                        return 0
                    }
                },
            },
    data () {
        return {
          renderCharts: false,
          holidaySummary: null,
        //   holidayData: {
        //     takenSeries: [12, 25],
        //     scheduledSeries: [1, 12],
        //     sicknessSeries: [14, 22],
        //   },
          chartTakeOptions: {
            colors: ['#09c2de', '#101924'],
            labels: ['Taken','Remaining'],
            legend: {
            show: false,
            },
            chart: {
              type: 'donut',
            },
          },
          chartScheduledOptions: {
            colors: ['#09c2de', '#101924'],
            labels: ['Scheduled','Remaining'],
            legend: {
            show: false,
            },
            chart: {
              type: 'donut',
            },
          },
          chartSicknessOptions: {
            colors: ['#09c2de', '#101924'],
            labels: ['Sick','Days Worked'],
             legend: {
                show: false,
            },
            chart: {
              type: 'donut',
            },
          },
        }
    }
}
</script>

<style>

</style>