<template>
    <div>
        <div class="row g-gs">
            <div class="col-lg-10">
                <div class="card card-shadow">
                    <div class="card-inner">
                        <div class="card-title">
                            <h6>My Holiday Stats</h6>
                        </div>
                        <holidaysummarycharts :userId="userId" />
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-primary btn-block" :to="{name: 'employees'}">Employees</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-dark btn-block" :to="{name: 'holidayrequests'}">View All Holiday Stats</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-success btn-block" :to="{name: 'mycalendar'}">Calendar</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <managerpeopleoverview />
            </div>
            <!-- end of holiday by month -->
        </div>
    </div>
</template>

<script>
import managerpeopleoverview from '@/components/widgets/people/managerpeopleoverview'
import holidaysummarycharts from '@/components/holiday/holidaysummarycharts'

export default {
    components: { managerpeopleoverview, holidaysummarycharts },
    data() {
        return {
            
        }
    },
    created() {
        this.getView()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/people')
        .then(() => { 
        })
        .catch(() => { 
        })},
    }
}
</script>

<style scoped>
.subtitle {
    font-size: 20px
}
</style>